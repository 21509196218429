export const PAYMENT_SYSTEMS = [
  {
    name: 'Checkout UAE',
    value: 'CHECKOUT_UAE',
    countries: ['ae'],
    attributes: {
      paymentSystem: 'CHECKOUT_UAE',
      payoutPricePlanType: 'BY_PERIOD',
    },
    disabled: false,
  },
  {
    name: 'Checkout UK',
    value: 'CHECKOUT',
    countries: ['gb', 'de', 'ae'],
    attributes: {
      paymentSystem: 'CHECKOUT',
      payoutPricePlanType: 'BY_PERIOD',
    },
    disabled: true,
  },
  {
    name: 'Checkout UK Dashboard',
    value: 'CHECKOUT_UK_DASHBOARD',
    countries: ['gb', 'de', 'ae'],
    attributes: {
      paymentSystem: 'CHECKOUT_UK_DASHBOARD',
      payoutPricePlanType: null,
    },
    disabled: false,
  },
  {
    name: 'Stripe',
    value: 'STRIPE',
    countries: ['gb', 'de'],
    attributes: {
      paymentSystem: 'STRIPE',
      payoutPricePlanType: null,
    },
    disabled: false,
  },
  {
    name: 'PayerMax',
    value: 'PAYER_MAX',
    countries: ['ae'],
    attributes: {
      paymentSystem: 'PAYER_MAX',
      payoutPricePlanType: 'BY_PERIOD',
    },
  },
  {
    name: 'Ryft',
    value: 'RYFT',
    countries: ['gb'],
    attributes: {
      paymentSystem: 'RYFT',
      payoutPricePlanType: null,
    },
  },
];

export const PAYMENT_SYSTEMS_REFUND = ['CHECKOUT', 'CHECKOUT_UAE', 'PAYER_MAX'];
export const PAYMENT_SYSTEMS_PLANS = ['CHECKOUT', 'CHECKOUT_UAE', 'PAYER_MAX'];
export const PAYMENT_SYSTEMS_REDIRECT_REPORTING = ['CHECKOUT', 'CHECKOUT_UAE', 'PAYER_MAX'];
export const CHECKOUT_PAYMENT_SYSTEM_LIST = ['CHECKOUT', 'CHECKOUT_UAE'];
export const DEFAULT_PAYMENT_SYSTEM = PAYMENT_SYSTEMS[0];
